import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import logo from "./LTDLogo.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          alt="logo"
        />

        <ReactPlayer
          url="https://stream.mux.com/Ii00gdP502TssndESRNf8BaVS3XphivbdT.m3u8"
          playing
          controls
          width="80%"
          height="auto"
        />
      </header>
    </div>
  );
}

export default App;

const PlayerStyle = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;
